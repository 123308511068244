export const CONFIG = {
  roles: {
    CP: "ROLE_COMMUNITY_PRESIDENT",
    C: "ROLE_COLLABORATOR",
    A: "ROLE_ADMIN",
    SA: "ROLE_SUPER_ADMIN"
  },
  auth: {
    login: {
      google: false
    },
    register: {
      enabled: false
    },
  }
}

//fixme: duplicated
export const ROLES = CONFIG.roles;


