import React from "react";
import {Card, CardBody} from "../../../../_metronic/_partials/controls";
import {AESVG, SVG_ICON} from "../../../../_ae/components/svg";
import {useIntl} from "react-intl";
import {AELink} from "../../../../_ae/components/AELink";
import {Name} from "./Name";
import clsx from "clsx";
import {DropdownCustomToggler,} from "../../../../_metronic/_partials/dropdowns";
import {Dropdown} from "react-bootstrap";
import {getRoute} from "../../../Routes";
import {AERole} from "../../../../_ae/components/AERole";
import {AETruncate} from "../../../../_ae/helpers/UIHelper";
import {AEMoment} from "../../../../_ae/components/moment";
import {Status} from "./Status";
import {UserEntityCard} from "../../Users";

export const EntityCard = ({
  entity,
  size= "md",
  className = '',
  avatar,
  editAction,
  deleteAction,
}) => {

  const { formatMessage } = useIntl()

  const classes = {
    xs:{
      card: 'shadow-none ',
      cardBody: 'p-1',
      symbol: 'symbol-40 mr-2',
      title: 'h6',
    },
    sm:{
      card: 'shadow-none ',
      cardBody: 'p-2',
      symbol: 'symbol-70 mr-2',
      title: 'h5',
    },
    md:{
      symbol: 'symbol-70 mr-5',
      cardBody: 'p-3 d-flex flex-column',
      title: 'h4',
    },
    lg:{
      symbol: 'symbol-80 mr-5',
      title: 'h3',
      cardBody: 'p-4',
    },
  }

  const sizeClasses = classes[size];
  const isLg = size === 'lg'
  const isMd = size === 'md'
  const isXs = size === 'xs'

  const actions = [
    {id: 'TASKS.EDIT', show: editAction},
    {id: 'TASKS.DELETE', show: deleteAction},
  ].filter(action=>action.show);


  return (
    <>
      <Card className={`${className}`}>
        <CardBody className={`${sizeClasses.cardBody}`}>
          <div className={`d-flex`}>
            <div className="flex-grow-1">
              <div className={`d-flex justify-content-between flex-wrap mt-1`}>
                <div className={`w-100 ${isXs ? '' : '-d-flex align-items-center'}`}>
                  <div className={'d-flex aligh-items-center'}>
                    <Name entity={entity} fontSize={sizeClasses.title} fontWeight={'bolder'}/>
                    <Status statusKey={entity.status}/>

                    <div className={'ml-auto'}>
                      {
                        actions.length > 0 &&
                        <Dropdown>
                          <Dropdown.Toggle as={DropdownCustomToggler} >
                            <AESVG path={SVG_ICON.DOTS} variant={'primary'} />
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            {
                              actions
                                .map(action=>{
                                  const route = getRoute(action.id);
                                  if (! route) return '';
                                  const path = route.path.replace(':id', entity.id)

                                  return (
                                    <AERole key={route.id} roles={route.roles}>
                                      <AELink to={path} className="dropdown-item" >
                                        <AESVG className={'pr-2'} path={route.svg}/>
                                        {formatMessage({id: route.id})}
                                      </AELink>
                                    </AERole>
                                  )
                                })
                            }
                          </Dropdown.Menu>
                        </Dropdown>
                      }
                    </div>
                  </div>
                  {
                    entity.assignTo &&
                    <UserEntityCard
                      entity={entity.assignTo}
                      size={'sm'}
                      avatar
                      className={'card-border mb-3'}
                    />
                  }
                  {
                    entity.description &&
                    <div className={'bg-light p-4 mb-3'}>
                      <AETruncate
                        text={entity.description}
                      />
                    </div>
                  }


                  <div className={clsx('flex-grow-1', !isXs && 'pt-1')} >
                    {
                      [
                        {
                          id: "DEADLINE",
                          value: <AEMoment date={entity.deadline} format={'LLLL'} />
                        },
                        {
                          id: "CREATED_AT",
                          value: <AEMoment date={entity.createdAt} format={'LLLL'} />
                        },
                        {
                          id: "UPDATED_AT",
                          hidden: !entity.updatedAt,
                          value: <AEMoment date={entity.updatedAt} format={'LLLL'} />
                        },
                      ]
                        .filter(op=>!op.hidden)
                        .map(op=>(
                          <div key={op.id} className="d-flex pb-1">
                            <span className="">
                              {op.value}
                              {
                                !isXs &&
                                <span className={"text-muted ml-2"}> {formatMessage({id: op.id})} </span>
                              }
                            </span>
                          </div>
                        ))
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </CardBody>
      </Card>
    </>
  )
}