import {all} from "redux-saga/effects";
import {combineReducers} from "redux";

import * as auth from "../app/modules/Auth/_redux/authRedux";
import {
  tasksUsersFilterName, tasksUsersFilterReducer,
  usersDeleteName, usersDeleteReducer,
  usersEditName,
  usersEditReducer, UsersForTasksEditName, UsersForTasksEditReducer,
  usersListName,
  usersListReducer
} from "./store/users";
import {
  locationsDeleteName, locationsDeleteReducer,
  locationsEditName,
  locationsEditReducer,
  locationsListName,
  locationsListReducer, locationsUsersFilterName, locationsUsersFilterReducer
} from "./store/locations";
import {
  citiesDeleteName,
  citiesDeleteReducer,
  citiesEditName,
  citiesEditReducer,
  citiesListName,
  citiesListReducer,
  citiesLocationsEditName,
  citiesLocationsEditReducer,
  citiesLocationsFilterName,
  citiesLocationsFilterReducer
} from "./store/cities";
import {
  tasksDeleteName,
  tasksDeleteReducer,
  tasksEditName,
  tasksEditReducer,
  tasksListName,
  tasksListReducer
} from "./store/tasks";
import {
  ModelsDeleteName,
  ModelsDeleteReducer,
  ModelsEditName,
  ModelsEditReducer,
  ModelsListName,
  ModelsListReducer,
  ModelsForReportsFilterReducer, ModelsForReportsFilterName, ModelsForReportsEditReducer, ModelsForReportsEditName
} from "./store/models";
import {
  reportsDeleteName, reportsDeleteReducer, reportsDetailsName, reportsDetailsReducer,
  reportsEditName,
  reportsEditReducer,
  reportsListName,
  reportsListReducer
} from "./store/reports";


export const rootReducer = combineReducers({
  auth: auth.reducer,
  //users
  [usersListName]: usersListReducer,
  [usersEditName]: usersEditReducer,
  [usersDeleteName]: usersDeleteReducer,
  [tasksUsersFilterName]: tasksUsersFilterReducer,
  [UsersForTasksEditName]: UsersForTasksEditReducer,
  //locations
  [locationsListName]: locationsListReducer,
  [locationsEditName]: locationsEditReducer,
  [locationsDeleteName]: locationsDeleteReducer,
  [locationsUsersFilterName]: locationsUsersFilterReducer,
  //locations
  [citiesListName]: citiesListReducer,
  [citiesEditName]: citiesEditReducer,
  [citiesDeleteName]: citiesDeleteReducer,
  [citiesLocationsFilterName]: citiesLocationsFilterReducer,
  [citiesLocationsEditName]: citiesLocationsEditReducer,
  //locations
  [tasksListName]: tasksListReducer,
  [tasksEditName]: tasksEditReducer,
  [tasksDeleteName]: tasksDeleteReducer,
  //locations
  [ModelsListName]: ModelsListReducer,
  [ModelsEditName]: ModelsEditReducer,
  [ModelsDeleteName]: ModelsDeleteReducer,
  [ModelsForReportsFilterName]: ModelsForReportsFilterReducer,
  [ModelsForReportsEditName]: ModelsForReportsEditReducer,
  //locations
  [reportsListName]: reportsListReducer,
  [reportsEditName]: reportsEditReducer,
  [reportsDeleteName]: reportsDeleteReducer,
  [reportsDetailsName]: reportsDetailsReducer,
});

export function* rootSaga() {
  yield all([auth.saga()]);
}
