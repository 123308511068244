import {useState} from "react";
import {localField} from "./helpers/UIHelper";

export class AEMetadata {
  constructor( pagination = {}, sort = {}, filters = {}, columns = {"*":[]}, id= "id") {
    const { page = 1, pages = 0, perPage = 10, total = 0} = pagination;
    const { field = "id", asc = true} = sort;
    const { search = "", createdAt, updatedAt } = filters;
    this.pagination = { page, pages, perPage, total };
    this.sort = { field, asc };
    this.filters = {search, createdAt, updatedAt, ...filters};

    this.columns = columns;
    this.id = id;
  }
}


// const metadataContext = createContext();
//
// export function useMetadataContext() {
//   return useContext(metadataContext);
// }

// const initial = new AEMetadata()

export function useMetadata(metadata = new AEMetadata()) {
  // console.log('eee',metadata)
  const [state, setState] = useState(metadata);

  const {pagination:{pages, page, total, perPage}, sort:{field, asc}, filters:{search, createdAt, updatedAt}, columns} = state;


  // setPages,
  const setPages = (pages) =>{
    setState({...state, pagination:{...state.pagination, pages}});
  }
  const setPerPage = perPage =>{
    setState({...state, pagination:{...state.pagination, perPage}});
  }
  const setTotal = total =>{
    setState({...state, pagination:{...state.pagination, total}});
  }
  const setSortField = field =>{
    setState({...state, sort:{...state.sort, field}});
  }
  const setSort = (field = 'id',asc=true) =>{
    setState({...state, sort:{field, asc}});
  }
  const setSortAsc = asc =>{
    setState({...state, sort:{...state.sort, asc}});
  }
  const setFilters = filters =>{
    setState({...state, filters});
  }
  const setSearch = search =>{
    setState({...state, filters:{...state.filters, search}});
  }

  const setCreatedAt = createdAt =>{
    setState({...state, filters:{...state.filters, createdAt}});
  }

  const setColumns = columns =>{
    setState({...state, columns});
  }

  const to = (_page)=>{
    switch (true){
      case _page === page : return;

      case _page < 1 : first(); break;
      case _page > pages : last(); break;

      default: setState({...state, pagination:{...state.pagination, page: _page}});
    }
  }
  const first = ()=>{ to(1) }
  const last = ()=>{ to(state.pagination.pages) }
  const next = ()=>{ to(state.pagination.page+1); }
  const prev = ()=>{ to(state.pagination.page-1); }
  const toAEMetadata = ()=>{
    // console.log()
    return new AEMetadata(
      {page,perPage,total,pages},
      {field, asc},
      {search, createdAt, updatedAt, ...state.filters},
      columns
    )
  }


  return {
    meta: state, setMeta : setState,
    page: state.pagination.page,
    pages: state.pagination.pages,
    perPage: state.pagination.perPage,
    total: state.pagination.total,
    sortField: state.sort.field,
    sortAsc: state.sort.asc,
    filters: state.filters,
    sort: state.sort,
    columns: state.columns,
    first,
    last,
    next,
    prev,
    to,
    toAEMetadata,
    setPages,
    setPerPage,
    setTotal,
    setSort,
    setSortField,
    setSortAsc,
    setFilters,
    setSearch,
    setCreatedAt,
    setColumns
  }
  //
  // const [page, setPage] = useState(initial.pagination.page);
  // const [pages, setPages] = useState(initial.pagination.pages);
  // const [perPage, setPerPage] = useState(initial.pagination.perPage);
  // const [total, setTotal] = useState(initial.pagination.perPage);
  // const [sortField, setSortField] = useState(initial.sort.field);
  // const [sortAsc, setSortAsc] = useState(initial.sort.asc);
  // const [filters, setFilters] = useState(initial.filters);
  // const [columns, setColumns] = useState(initial.select);
  // //
  // const to = (_page)=>{
  //   switch (true){
  //     case _page === page : return;
  //
  //     case _page < 1 : first(); break;
  //     case _page > pages : last(); break;
  //
  //     default: setPage(_page);
  //   }
  // }
  // const first = ()=>{ to(1) }
  // const last = ()=>{ to(pages) }
  // const next = ()=>{ to(page+1); }
  // const prev = ()=>{ to(page-1); }
  // const toAEMetadata = ()=>{
  //   return new AEMetadata({page,perPage,total,pages},{field:sortField, asc:sortAsc},filters, columns)
  // }
  //
  //
  // return {
  //   page, pages, perPage, total, sortField, sortAsc, filters, columns,
  //   first, last, next, prev, to, toAEMetadata,
  //   setPages, setPerPage, setTotal, setSortField, setSortAsc, setFilters, setColumns
  // }
}

export const defaultMeta = new AEMetadata({perPage: 1000}, {}, {}, ['id', localField()]);