import {toAbsoluteUrl} from "../../_metronic/_helpers";
import {getConfig} from "../../_metronic/i18n";

export const API_UPLOAD_PATH = `/uploads`;
export const API_DOCUMENTS_PATH = `${API_UPLOAD_PATH}/documents`;


export const languages = [
  {
    lang: "ar",
    name: "ARABIC",
    flag: toAbsoluteUrl("/media/svg/flags/166-morocco.svg"),
    rtl:true //todo
  },
  {
    lang: "en",
    name: "ENGLISH",
    flag: toAbsoluteUrl("/media/svg/flags/226-united-states.svg"),
  },
  {
    lang: "fr",
    name: "FRENCH",
    flag: toAbsoluteUrl("/media/svg/flags/195-france.svg"),
  },
  {
    lang: "es",
    name: "SPANISH",
    flag: toAbsoluteUrl("/media/svg/flags/128-spain.svg"),
  }
];
export const languagesKeys = languages.map(l=>l.lang)
// export const localField = (name = 'name') => `${name}_${getConfig().selectedLang}`
export const localField = (name = 'name') => `${name}`
export const getFieldNames = name => languagesKeys.map(l=>`${name}_${l}`)


export const PAYMENT_TERMS = {
  HOURLY : 'HOURLY',
  FIXED : 'FIXED',
  UNKNOWN : 'UNKNOWN',
}
export const PTermsHelpers = {
  isKnown : (_key)=>[PAYMENT_TERMS.HOURLY, PAYMENT_TERMS.FIXED].includes(_key),
  isUnknown : (_key)=>[PAYMENT_TERMS.UNKNOWN].includes(_key),
  isHourly : (_key)=>[PAYMENT_TERMS.HOURLY].includes(_key),
  getKey: (paymentTerms, id)=>{
    const i = paymentTerms.findIndex(pt=>pt.id === id)
    return i === -1 ? null : paymentTerms[i]._key;
   }
}
export const PROPOSALS = {
  PENDING: "PENDING",
  ACCEPTED: "ACCEPTED",
  REJECTED: "REJECTED",
}
export const SITE_NAME = "CCJA"
export const getArrayOfNumbers = length => Array.from(Array(length).keys()).map(k => k + 1)
export const EmailDomains = [
  {id: 1, name: 'gmail'},
  {id: 2, name: 'yahoo'},
  {id: 3, name: 'hotmail'},
  {id: 4, name: 'outlook'},
]

export const AETruncate = ({text, length, useWordBoundary, className = ''} )=>{
  if (!text) return '';
  if (!length) return text;
  if (text.length <= length) return text;
  const subString = text.substr(0, length-1); // the original check

  return (useWordBoundary
    ? subString.substr(0, subString.lastIndexOf(" "))
    : subString) + " ...";
};

