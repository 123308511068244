import React from "react";
import {AELabel} from "../../../../_ae/components/AELabel";
import {useIntl} from "react-intl";

export const LOCATIONS_TYPES = {
  MUNICIPALITY : 'MUNICIPALITY', // baladya
  TERRITORY: 'TERRITORY' // Mo9ata3a
}
export const getLocationTypesKeys  = () => Object.values(LOCATIONS_TYPES)

export const LocationType = ({type})=>{
  const { formatMessage } = useIntl()

  return type && LOCATIONS_TYPES[type] ?
    <AELabel title={formatMessage({id: LOCATIONS_TYPES[type]})} /> :
    ''
}