import React, {useEffect} from "react";
import { useDispatch } from "react-redux";
import {useIntl} from "react-intl";
import {
  AEAutocompleteField,
  AEDatepickerField,
  AEField
} from "../../../../../_metronic/_partials/controls/forms/AEField";
import {fetchUsersForTasksEdit, useUsersForTasksEditState} from "../../../../../redux/store/users";
import {getFullName} from "../../../Users/components/FullName";
import {getStatus, getStatusKeys, Status} from "../../components/Status";


//todo server validation errors
export const Form = ({
                       values,
                       errors,
                       touched,
                       handleChange,
                       handleBlur,
                       handleSubmit,
                       isSubmitting,
                       btnRef,
}) => {
  const dispatch = useDispatch();
  const {formatMessage} = useIntl();
  const {data: users} = useUsersForTasksEditState()

  useEffect(()=>{
    dispatch(fetchUsersForTasksEdit())
  }, [dispatch])

  return (
    <div className="form form-label-right">
      <div className="form-group row">
        <div className={"col-lg-12"}>
          <AEField
            name="name"
            label={'NAME'}
          />
        </div>
      </div>

      <div className="form-group row">
        <div className={`col-md-4`}>
          <AEAutocompleteField
            name="assignTo"
            label={'ASSIGN_TO'}
            options={users}
            getOptionLabel={getFullName}
          />
        </div>
        <div className={`col-md-4`}>
          <AEAutocompleteField
            name="status"
            label={'STATUS'}
            options={getStatusKeys()}
            getOptionLabel={o=>formatMessage({id: getStatus(o).id})}
            renderOption={o=><Status statusKey={o} />}
          />
        </div>
        <div className={`col-md-4`}>
          <AEDatepickerField
            name="deadline"
            label={'DEADLINE'}
          />
        </div>
      </div>

      <div className="form-group row">
        <div className={"col-lg-12"}>
          <AEField
            name="description"
            label={'DESCRIPTION'}
            type={'textarea'}
            rows={12}
          />
        </div>
      </div>



      <button
        style={{ display: "none" }}
        ref={btnRef}
        onClick={handleSubmit}
      />
    </div>
  );
}

