import {getSlice, MODULES, useCustomSelector} from "../../helpers";
import {AEMetadata} from "../../../../_ae/AEPagination";
import * as API from "../api";


const {actions, name, reducer} = getSlice({
  name: `${MODULES.LOCATIONS}.tasksFilter`,
  data: [],
  metadata: new AEMetadata(
    {},
    {field: 'name'},
    {},
    {"*": []}
  )
})

const {startCall, endCall, fetched, catchError, reset} = actions;

const resetDispatcher = () => dispatch => {
  dispatch(reset());
}

const fetchDispatcher = metadata => dispatch => {
  dispatch(startCall());

  return API
    .all(metadata)
    .then(response => {
      dispatch(fetched(response));

      return response;
    })
    .catch(response => {
      dispatch(catchError(response));

      return response;
    }).then(response=>{
      dispatch(endCall(response));
    })
    ;
};

const useSelector = () => useCustomSelector(name)

export {
  actions,
  name,
  reducer,
  fetchDispatcher,
  resetDispatcher,
  useSelector
}


