import React from "react";
import {AELabel} from "../../../../_ae/components/AELabel";
import {useIntl} from "react-intl";

export const PERIODS = {
  DAILY : 'DAILY',
  WEEKLY: 'WEEKLY',
  MONTHLY: 'MONTHLY',
  QUARTERLY: 'QUARTERLY',
  ANNUAL: 'ANNUAL'
}
export const getPeriodKeys  = () => Object.values(PERIODS)

export const Period = ({type})=>{
  const { formatMessage } = useIntl()

  return type && PERIODS[type] ?
    <AELabel title={formatMessage({id: PERIODS[type]})} /> :
    ''
}