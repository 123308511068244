import React from "react";

export const getFullName = user => `${user.firstName} ${user.lastName}`

export const FullName = ({user, fontSize='md', fontWeight='bold'})=>{
  return (
    <span className={`font-size-${fontSize} font-weight-${fontWeight}`}>
      {user.firstName}
      {` `}
      {user.lastName}
    </span>
  )
}