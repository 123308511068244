import React, {useEffect} from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {Field, useField, useFormikContext} from "formik";
import {AEInput} from "./AEInput";
import TextField from "@material-ui/core/TextField";
import {localField} from "../../../../_ae/helpers/UIHelper";
import {useIntl} from "react-intl";
import {FieldFeedbackLabel} from "./FieldFeedbackLabel";
import {makeStyles} from "@material-ui/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import LinearProgress from "@material-ui/core/LinearProgress";
import { ProgressBar } from "react-bootstrap";

const getFieldCSSClasses = (touched, errors) => {
  const classes = [];
  if (touched && errors) {
    classes.push("is-invalid");
  }

  if (touched && !errors) {
    classes.push("is-valid");
  }

  return classes.join(" ");
};

const useStyles = makeStyles({
  borderless: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        border: 0
      }
    }
    // border: 0,
  }
});

export function AEAutoComplete({
  field: {name, value, ...fields}, // { name, value, onChange, onBlur }
  form: { touched, errors, setTouched,  setFieldValue, setFieldTouched, values, ...form }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  label,
  getOptionLabel = o=>o,
  withFeedbackLabel = true,
  customFeedbackLabel ,
  type = "text",
  validation = true,
  borderless = false,
  size="small",
  disabled = false,
  options = [],
  className = "",
  variant = "outlined",
  multiple = false,
  loading,
  ...props
}) {
  const {formatMessage} = useIntl()
  label = formatMessage({id:label})

  //console.log(props)

  return (
    <>
      <Autocomplete
        name={name}
        size={size} fullWidth multiple={multiple}
        options={options}
        getOptionLabel={getOptionLabel}
        value={value} //careful: form edit team
        // getOptionSelected={(option) => option === value}
        onChange={(_,v,reason) => {
          /*WARNING : null doesn't work correctly with Yup*/
          setFieldValue(name, reason === "clear" ? undefined : v);
        }}
        onBlur={ () => setFieldTouched(name,true) }
        disabled={disabled}

        {...props}
        renderInput={(params) => {
          return (
            <>
              <TextField {...params} label={label} variant={variant}/>
              {
                loading &&
                <ProgressBar
                  variant={"primary"}
                  animated
                  now={100}
                  className="h-2px align-self-center w-50"
                />
              }
              </>
          )

          // return (
          //   <div ref={params.InputProps.ref}>
          //     <input style={{ width: 200 }} type="text" {...params.inputProps} />
          //   </div>
          // )
          // for ( var i in params ) {
          //   if(i === "inputProps") console.log(i, params[i]);
          // }
          // params.InputProps.inputProps = params.inputProps;
          // delete params.InputProps;
          // params.inputProps.value = "aaa"
          // const {} = params.inputProps;
          // params.inputProps = {
          //
          // }

          //issue
          // console.log(params)
          /*
          * aria-activedescendant: null
            aria-autocomplete: "list"
            aria-controls: null
            autoCapitalize: "none"
            autoComplete: "off"
            className: "MuiAutocomplete-input MuiAutocomplete-inputFocused"
            disabled: false
            id: "mui-353"
            onBlur: ƒ handleBlur(event)
            onChange: ƒ handleInputChange(event)
            onFocus: ƒ handleFocus(event)
            onMouseDown: ƒ handleInputMouseDown(event)
            ref: {current: input#mui-353.MuiInputBase-input.MuiOutlinedInput-input.MuiAutocomplete-input.MuiAutocomplete-input…}
            spellCheck: "false"
            value: "fixx"
* */

          // params.inputProps = {
          //
          // }
          // console.clear()
          // console.log(value, params, props)

          // return (
          //   <Field
          //     component={AEInput}
          //     name={name}
          //     withFeedbackLabel={withFeedbackLabel} customFeedbackLabel={customFeedbackLabel}
          //     type={"select"}
          //     label={label}
          //     shrinkLabel
          //     borderless={borderless}
          //     validation={validation}
          //     {...params}
          //   />
          // )
        }}
      />
      {!disabled && withFeedbackLabel && (
        <FieldFeedbackLabel
          error={errors[name]}
          touched={touched[name]}
          label={label}
          type={type}
          customFeedbackLabel={customFeedbackLabel}
        />
      )}
    </>
  );
}
