import {getConfig} from "../_metronic/i18n";
import {ROLES} from "./config";

class AEMenuItem {
  constructor({id = 'index', title, route_path = "/", svg="/Design/Target.svg", roles = [], i18nValues={}, i18Descriptor={}}) {
    // this.id = id;
    this.id = id;
    this.title = title;
    this.route_path = route_path;
    this.svg = svg;
    this.children = [];
    this.parent = null;
    this.actions = null;
    this.i18Descriptor = i18Descriptor;
    this.i18nValues = i18nValues;
  }

  addChild(item){
    item.parent = this;
    this.children.push(item)
    return this;
  }

  hasChildren(){
    return this.children.length > 0;
  }

  /**/
}



const config = getConfig();


/* TODO improve
* [ ] recursive
* */
export const getMenuItemByPathname = (pathname)=>{
  let item = [];
  getMenu().forEach(e=>{
    if(e.route_path === pathname){
      item = e;
    }else {
      e.children.forEach(e2=>{
        if(e2.route_path === pathname){
          item = e2;
        }else {
          e2.children.forEach(e3=>{
            if(e3.route_path === pathname){
              item = e3;
            }
          })
        }
      })
    }

  })

  return item;

  // const getItem = (item, part)=>{
  //   console.log(item)
  //   return item.find(e=>e.route_path === `/${part}`)
  // }
  // // parts.forEach(part=>{
  // //   item = menu.find(e=>e.route_path === `/${part}`)
  // // })
  //
  // console.log(/*menu,pathname, */getItem(menu.find(e=>e.route_path === `/${parts[0]}`), parts))
}



export const getMenu = (role = config.role) => {
  let MENU = [];
  const Dashboard = new AEMenuItem({id: "dashboard", title: "DASHBOARD", svg: "/Design/Layers.svg", route_path: "/dashboard"});
  const User = new AEMenuItem({
    id:"users",
    title:"USERS",
    svg:"/Communication/Group.svg",
    route_path:"/users"
  });

  const Location = new AEMenuItem({
    id:"locations",
    title:"LOCATIONS",
    svg:"/Map/Position.svg",
    route_path:"/locations"
  })

  const City = new AEMenuItem({
    id:"cities",
    title:"CITIES",
    svg:"/Map/Marker1.svg",
    route_path:"/cities"
  })

  const Task = new AEMenuItem({
    id:"tasks",
    title:"TASKS",
    svg:"/Navigation/Double-check.svg",
    route_path:"/tasks"
  })

  const Log = new AEMenuItem({
    id:"logs",
    title:"LOGS",
    svg:"/Communication/Clipboard-check.svg",
    route_path:"/logs"
  })


  if([ROLES.SA].includes(role)){
    MENU.push(User, Location, City, Task, Log)
  }

  if([ROLES.A].includes(role)){
    MENU.push(User, Location, City, Task)
  }

  if([ROLES.CP].includes(role)){
    MENU.push(Task)
  }

  if([ROLES.C].includes(role)){
    MENU.push(Task)
  }

  return MENU;
}
