import {AEInput} from "./AEInput";
import {Field} from "formik";
import React from "react";
import {AEAutoComplete} from "./AEAutoComplete";
import {AEDatePicker} from "./AEDatePicker";
import {AEFile} from "./AEFile";

export const AEField = ({name, label, component = AEInput, ...props}) => (
  <Field
    name={name}
    label={label}
    component={component}
    {...props}
  />
)

export const AEAutocompleteField = ({...props}) => (
  <AEField
    component={AEAutoComplete}
    getOptionSelected={(o, v)=> {
      if (o && o.id) {
        return o.id === v.id
      }
      return o === v
    }}
    {...props}
  />
)

export const AEDatepickerField = ({...props}) => (
  <AEField
    component={AEDatePicker}
    {...props}
  />
)

export const AEFileField = ({...props}) => (
  <AEField
    component={AEFile}
    {...props}
  />
)