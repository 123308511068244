import React from "react";
import {Card, CardBody} from "../../../../_metronic/_partials/controls";
import {AESVG, SVG_ICON} from "../../../../_ae/components/svg";
import {useIntl} from "react-intl";
import {AELink} from "../../../../_ae/components/AELink";
import {Name} from "./Name";
import clsx from "clsx";
import {DropdownCustomToggler,} from "../../../../_metronic/_partials/dropdowns";
import {Dropdown} from "react-bootstrap";
import {getRoute} from "../../../Routes";
import {AERole} from "../../../../_ae/components/AERole";
import {AETruncate} from "../../../../_ae/helpers/UIHelper";
import {AEMoment} from "../../../../_ae/components/moment";
import {Period} from "./Period";
import {UserEntityCard} from "../../Users";
import {ModelEntityCard} from "../../Models";
import {Role} from "../../Users/components/Role";
import {AEFileDownload} from "../../../../_ae/components/AEFileDownload";

export const EntityCard = ({
                             entity,
                             size= "md",
                             className = '',
                             avatar,
                             editAction,
                             deleteAction,
                             detailsAction
                           }) => {

  const { formatMessage } = useIntl()

  const classes = {
    xs:{
      card: 'shadow-none ',
      cardBody: 'p-1',
      symbol: 'symbol-40 mr-2',
      title: 'h6',
    },
    sm:{
      card: 'shadow-none ',
      cardBody: 'p-2',
      symbol: 'symbol-70 mr-2',
      title: 'h5',
    },
    md:{
      symbol: 'symbol-70 mr-5',
      cardBody: 'p-3 d-flex flex-column',
      title: 'h4',
    },
    lg:{
      symbol: 'symbol-80 mr-5',
      title: 'h3',
      cardBody: 'p-4',
    },
  }

  const sizeClasses = classes[size];
  const isLg = size === 'lg'
  const isMd = size === 'md'
  const isXs = size === 'xs'

  const actions = [
    {id: 'REPORTS.EDIT', show: editAction},
    {id: 'REPORTS.DELETE', show: deleteAction},
    {id: 'REPORTS.DETAILS', show: detailsAction},
  ].filter(action=>action.show);


  return (
    <>
      <Card className={`${className}`}>
        <CardBody className={`${sizeClasses.cardBody}`}>
          <div className={`d-flex`}>
            <div className="flex-grow-1">
              <div className={`d-flex justify-content-between flex-wrap mt-1`}>
                <div className={`w-100 ${isXs ? '' : '-d-flex align-items-center'}`}>
                  <div className={'d-flex aligh-items-center'}>
                    <Name entity={entity} fontSize={sizeClasses.title} fontWeight={'bolder'}/>
                    <div className={'ml-auto'}>
                      {
                        actions.length > 0 &&
                        <Dropdown>
                          <Dropdown.Toggle as={DropdownCustomToggler} >
                            <AESVG path={SVG_ICON.DOTS} variant={'primary'} />
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            {
                              actions
                                .map(action=>{
                                  const route = getRoute(action.id);
                                  if (! route) return '';
                                  const path = route.path.replace(':id', entity.id)

                                  return (
                                    <AERole key={route.id} roles={route.roles}>
                                      <AELink to={path} className="dropdown-item" >
                                        <AESVG className={'pr-2'} path={route.svg}/>
                                        {formatMessage({id: route.id})}
                                      </AELink>
                                    </AERole>
                                  )
                                })
                            }
                          </Dropdown.Menu>
                        </Dropdown>
                      }
                    </div>
                  </div>
                  <div className={'row'}>
                    <div className={'col-md-6'}>
                      {
                        entity.model &&
                        <ModelEntityCard
                          entity={entity.model}
                          size={'xs'}
                          avatar
                          className={'bg-light card-border mb-3'}
                        />
                      }
                    </div>
                    <div className={'col-md-6'}>
                      <div className={clsx('flex-grow-1', !isXs && 'pt-1')} >
                        {
                          [
                            {
                              id: "PERIODIC",
                              value: <Period type={entity.periodic}/>
                            },
                            {
                              id: "CREATED_AT",
                              value: <AEMoment date={entity.createdAt} format={'LLLL'} />
                            }
                          ]
                            .filter(op=>!op.hidden)
                            .map(op=>(
                              <div key={op.id} className="d-flex pb-1">
                            <span className="">
                              {op.value}
                              {
                                !isXs &&
                                <span className={"text-muted ml-2"}> {formatMessage({id: op.id})} </span>
                              }
                            </span>
                              </div>
                            ))
                        }
                      </div>
                    </div>
                  </div>
                  {
                    entity.description &&
                    <div className={'bg-light p-4'}>
                      <AETruncate
                        text={entity.description}
                      />
                    </div>
                  }

                </div>
              </div>
            </div>
          </div>

          {
            (isMd || isLg) &&
            entity.attachments &&
            entity.attachments.length > 0 &&
            <div className={'mt-auto'}>
              <div className="separator separator-solid my-2"/>
              <div className="">
                {
                  entity.attachments.map(attachment=>(
                    <AEFileDownload
                      key={attachment.id}
                      target={'report_attachments'}
                      fileName={attachment.fileName}
                  />
                  ))
                }
              </div>
            </div>

          }
        </CardBody>
      </Card>
    </>
  )
}