import {getSlice, MODULES, useCustomSelector} from "../../helpers";
import * as API from "../api";

const {actions, name, reducer} = getSlice({
  name: `${MODULES.REPORTS}.edit`,
  data: {
    title: '',
    description: '',
    periodic: undefined,
    model: undefined,
    attachments: []
  },
})

const {startCall, endCall, fetched, catchError, reset} = actions;


const resetDispatcher = () => dispatch => {
  dispatch(reset());
}

const fetchDispatcher = (id, metadata) => dispatch => {
  dispatch(startCall());

  return API
    .find(id, metadata)
    .then(response => {
      dispatch(fetched(response));
    })
    .catch(response => {
      dispatch(catchError(response));
    }).then(response => {
      dispatch(endCall(response));
    })
    ;
};

const saveDispatcher = (entity, files) => dispatch => {
  dispatch(startCall());

  const ApiCall = entity.id ?
    API.update:
    API.create;
  console.log(entity, files)
  return ApiCall(entity, files)
    .then(response => {
      dispatch(fetched(response));
    })
    .catch(response => {
      dispatch(catchError(response));
    }).then(response => {
      dispatch(endCall(response));
    })
    ;
};

const useSelector = () => useCustomSelector(name)


export {
  actions,
  name,
  reducer,
  fetchDispatcher,
  resetDispatcher,
  saveDispatcher,
  useSelector
}
