import React from "react";
import {localField} from "../../_ae/helpers/UIHelper";

// TODO move to Location component
export const Location = ({location, fontSize='md', fontWeight='bold'})=>{
  if (!location) return ''

  const {city} = location;

  return (
    <>
      <span className={`font-size-${fontSize} font-weight-${fontWeight}`}>{location[localField()]}</span>
      {
        city &&
          <>
            {` `}
            <span className={'pl-2 text-muted'}>{location.city[localField()]}</span>
          </>
      }
    </>
  )
}