import React, {lazy, Suspense, useEffect} from "react";
import {Redirect, Route, Switch, useLocation} from "react-router-dom";
import {shallowEqual, useSelector} from "react-redux";
import {ContentRoute, Layout, LayoutSplashScreen} from "../_metronic/layout";
import {Logout} from "./modules/Auth";
import ErrorsPage from "./pages/ErrorsPages/ErrorsPage";
import {SVG_ICON} from "../_ae/components/svg";
import {CONFIG, ROLES} from "../_ae/config";
import {useIntl} from "react-intl";
import {SITE_NAME} from "../_ae/helpers/UIHelper";
import {matchPath} from "react-router";
import {AuthLayout} from "./modules/Auth/pages/AuthLayout";
import {UsersDelete, UsersEdit, UsersList} from "./modules/Users";
import {MODULES} from "../redux/store/helpers";
import {LocationsDelete, LocationsEdit, LocationsList} from "./modules/Locations";
import {CitiesDelete, CitiesEdit, CitiesList} from "./modules/Cities";
import {TasksDelete, TasksEdit, TasksList} from "./modules/Tasks";
import {ModelsDelete, ModelsEdit, ModelsList} from "./modules/Models";
import {ReportsDelete, ReportsDetails, ReportsEdit, ReportsList} from "./modules/Reports";

// Auth
const Login = lazy(() => import('./modules/Auth/pages/Login'));
const Registration = lazy(() => import('./modules/Auth/pages/Registration'));
const ForgotPassword = lazy(() => import('./modules/Auth/pages/ForgotPassword'));
// Users

export const VIEWS = {
  MENU : 'MENU',
  ACTION: 'ACTION',
  DIALOG: 'DIALOG'
}


const ROUTES = [
  // {
  //   id: 'INDEX',
  //   pathname: `/`,
  //   svg: SVG_ICON.PLUS,
  //   component: DashboardPage,
  //   accepts: [ROLES.SA, ROLES.A],
  // },
  {
    id: 'AUTH.LOGIN',
    path: `/auth/login`,
    svg: SVG_ICON.ADD_USER,
    component: Login,
    roles: [ROLES.V],
    context: 'auth',
    views: []
  },
  {
    id: 'AUTH.FORGOT',
    path: `/auth/forgot-password`,
    svg: SVG_ICON.ADD_USER,
    component: ForgotPassword,
    roles: [ROLES.V],
    context: 'auth',
    views: []
  },
  {
    id: 'AUTH.REGISTER',
    path: `/auth/registration`,
    svg: SVG_ICON.ADD_USER,
    component: Registration,
    roles: CONFIG.auth.register.enabled ? [ROLES.V] : [],
    context: 'auth',
    views: []
  },
  // users
  {
    id: 'USERS.NEW',
    path: `/${MODULES.USERS}/new`,
    svg: SVG_ICON.ADD_USER,
    component: UsersEdit,
    roles: [ROLES.SA, ROLES.A],
    context: MODULES.USERS,
    views: [VIEWS.ACTION]
  },
  {
    id: 'USERS.EDIT',
    path: `/${MODULES.USERS}/:id/edit`,
    svg: SVG_ICON.EDIT,
    component: UsersEdit,
    roles: [ROLES.SA, ROLES.A],
    context: MODULES.USERS,
    views: []
  },
  {
    id: 'USERS.DELETE',
    path: `/${MODULES.USERS}/:id/delete`,
    svg: SVG_ICON.DELETE,
    component: UsersDelete,
    roles: [ROLES.SA, ROLES.A],
    context: MODULES.USERS,
    views: [VIEWS.DIALOG]
  },
  {
    id: 'USERS.LIST',
    path: `/${MODULES.USERS}/list`,
    svg: SVG_ICON.USERS,
    component: UsersList,
    roles: [ROLES.SA, ROLES.A],
    context: MODULES.USERS,
    views: [VIEWS.MENU, VIEWS.ACTION]
  },
  // locations
  {
    id: 'LOCATIONS.NEW',
    path: `/${MODULES.LOCATIONS}/new`,
    svg: SVG_ICON.PLUS,
    component: LocationsEdit,
    roles: [ROLES.SA, ROLES.A],
    context: MODULES.LOCATIONS,
    views: [VIEWS.ACTION]
  },
  {
    id: 'LOCATIONS.EDIT',
    path: `/${MODULES.LOCATIONS}/:id/edit`,
    svg: SVG_ICON.EDIT,
    component: LocationsEdit,
    roles: [ROLES.SA, ROLES.A],
    context: MODULES.LOCATIONS,
    views: []
  },
  {
    id: 'LOCATIONS.DELETE',
    path: `/${MODULES.LOCATIONS}/:id/delete`,
    svg: SVG_ICON.DELETE,
    component: LocationsDelete,
    roles: [ROLES.SA, ROLES.A],
    context: MODULES.LOCATIONS,
    views: [VIEWS.DIALOG]
  },
  {
    id: 'LOCATIONS.LIST',
    path: `/${MODULES.LOCATIONS}/list`,
    svg: SVG_ICON.LOCATIONS,
    component: LocationsList,
    roles: [ROLES.SA, ROLES.A],
    context: MODULES.LOCATIONS,
    views: [VIEWS.MENU, VIEWS.ACTION]
  },
  // cities
  {
    id: 'CITIES.NEW',
    path: `/${MODULES.CITIES}/new`,
    svg: SVG_ICON.PLUS,
    component: CitiesEdit,
    roles: [ROLES.SA, ROLES.A],
    context: MODULES.CITIES,
    views: [VIEWS.ACTION]
  },
  {
    id: 'CITIES.EDIT',
    path: `/${MODULES.CITIES}/:id/edit`,
    svg: SVG_ICON.EDIT,
    component: CitiesEdit,
    roles: [ROLES.SA, ROLES.A],
    context: MODULES.CITIES,
    views: []
  },
  {
    id: 'CITIES.DELETE',
    path: `/${MODULES.CITIES}/:id/delete`,
    svg: SVG_ICON.DELETE,
    component: CitiesDelete,
    roles: [ROLES.SA, ROLES.A],
    context: MODULES.CITIES,
    views: [VIEWS.DIALOG]
  },
  {
    id: 'CITIES.LIST',
    path: `/${MODULES.CITIES}/list`,
    svg: SVG_ICON.CITIES,
    component: CitiesList,
    roles: [ROLES.SA, ROLES.A],
    context: MODULES.CITIES,
    views: [VIEWS.MENU, VIEWS.ACTION]
  },
  //tasks
  {
    id: 'TASKS.NEW',
    path: `/${MODULES.TASKS}/new`,
    svg: SVG_ICON.PLUS,
    component: TasksEdit,
    roles: [ROLES.SA, ROLES.A],
    context: MODULES.TASKS,
    views: [VIEWS.ACTION]
  },
  {
    id: 'TASKS.EDIT',
    path: `/${MODULES.TASKS}/:id/edit`,
    svg: SVG_ICON.EDIT,
    component: TasksEdit,
    roles: [ROLES.SA, ROLES.A],
    context: MODULES.TASKS,
    views: []
  },
  {
    id: 'TASKS.DELETE',
    path: `/${MODULES.TASKS}/:id/delete`,
    svg: SVG_ICON.DELETE,
    component: TasksDelete,
    roles: [ROLES.SA, ROLES.A],
    context: MODULES.TASKS,
    views: [VIEWS.DIALOG]
  },
  {
    id: 'TASKS.LIST',
    path: `/${MODULES.TASKS}/list`,
    svg: SVG_ICON.TASKS,
    component: TasksList,
    roles: [ROLES.SA, ROLES.A],
    context: MODULES.TASKS,
    views: [VIEWS.MENU, VIEWS.ACTION]
  },
  //Models
  {
    id: 'MODELS.NEW',
    path: `/${MODULES.MODELS}/new`,
    svg: SVG_ICON.PLUS,
    component: ModelsEdit,
    roles: [ROLES.SA, ROLES.A],
    context: MODULES.MODELS,
    views: [VIEWS.ACTION]
  },
  {
    id: 'MODELS.EDIT',
    path: `/${MODULES.MODELS}/:id/edit`,
    svg: SVG_ICON.EDIT,
    component: ModelsEdit,
    roles: [ROLES.SA, ROLES.A],
    context: MODULES.MODELS,
    views: []
  },
  {
    id: 'MODELS.DELETE',
    path: `/${MODULES.MODELS}/:id/delete`,
    svg: SVG_ICON.DELETE,
    component: ModelsDelete,
    roles: [ROLES.SA, ROLES.A],
    context: MODULES.MODELS,
    views: [VIEWS.DIALOG]
  },
  {
    id: 'MODELS.LIST',
    path: `/${MODULES.MODELS}/list`,
    svg: SVG_ICON.MODELS,
    component: ModelsList,
    roles: [ROLES.SA, ROLES.A],
    context: MODULES.MODELS,
    views: [VIEWS.MENU, VIEWS.ACTION]
  },
  //Models
  {
    id: 'REPORTS.NEW',
    path: `/${MODULES.REPORTS}/new`,
    svg: SVG_ICON.PLUS,
    component: ReportsEdit,
    roles: [ROLES.SA, ROLES.A],
    context: MODULES.REPORTS,
    views: [VIEWS.ACTION]
  },
  {
    id: 'REPORTS.EDIT',
    path: `/${MODULES.REPORTS}/:id/edit`,
    svg: SVG_ICON.EDIT,
    component: ReportsEdit,
    roles: [ROLES.SA, ROLES.A],
    context: MODULES.REPORTS,
    views: []
  },
  {
    id: 'REPORTS.DELETE',
    path: `/${MODULES.REPORTS}/:id/delete`,
    svg: SVG_ICON.DELETE,
    component: ReportsDelete,
    roles: [ROLES.SA, ROLES.A],
    context: MODULES.REPORTS,
    views: [VIEWS.DIALOG]
  },
  {
    id: 'REPORTS.DETAILS',
    path: `/${MODULES.REPORTS}/:id/details`,
    svg: SVG_ICON.LIST,
    component: ReportsDetails,
    roles: [ROLES.SA, ROLES.A],
    context: MODULES.REPORTS,
    views: [VIEWS.DIALOG]
  },
  {
    id: 'REPORTS.LIST',
    path: `/${MODULES.REPORTS}/list`,
    svg: SVG_ICON.REPORTS,
    component: ReportsList,
    roles: [ROLES.SA, ROLES.A],
    context: MODULES.REPORTS,
    views: [VIEWS.MENU, VIEWS.ACTION]
  },
]

export const getRoutes = (context) => {
  if(! context) return ROUTES

  return ROUTES.filter(route=>route.context === context)
}

export const getMenuRoutes = (context) => { // todo roles
  const routes = getRoutes(context)

  return routes.filter(route=>route.views.includes(VIEWS.MENU))
}

export const getActionRoutes = (context) => { // todo roles
  const routes = getRoutes(context)

  return routes.filter(route=>route.views.includes(VIEWS.ACTION))
}

export const getRoute = (id, property = 'id') => {
  const routes = getRoutes();
  const i = routes.findIndex(r=>r[property] === id);
  if(i === -1) return null;

  return routes[i];
}

export const getRouteByPathname = (pathname) => {
  return getRoutes().find(r=>matchPath(pathname, {path: r.path}))
}




/* TODO ALL ROUTING HERE ? basepage and contexts routes */
export function Routes() {
  const {isAuthorized, user} = useSelector( ({auth}) => ({ user: auth.user, isAuthorized: auth.user != null, }), shallowEqual );
  const {formatMessage} = useIntl();
  const location = useLocation();
  useEffect(
    ()=>{
      const currentRoute = getRouteByPathname(location.pathname);
      let title = SITE_NAME;
      if(currentRoute) {
        title += ` | ${formatMessage({id: currentRoute.id})}`
      }

      document.title = title;
    },[location.pathname])
  // console.log(location.pathname, isAuthorized)
  return (
    <>
      {/*{*/}
      {/*  getRoutes().filter(route=>route.views.includes(VIEWS.DIALOG)).map(route=>(*/}
      {/*    <ContentRoute*/}
      {/*      key={route.id}*/}
      {/*      onHide={() => {*/}
      {/*        // history.push("/users/list");*/}
      {/*      }}*/}
      {/*      {...route}*/}
      {/*    />*/}
      {/*  ))*/}
      {/*}*/}

      <Switch>
        {/*{*/}
        {/*  !isAuthorized ?*/}
        {/*    <Route> <AuthPage /> </Route> :*/}
        {/*    <Redirect from="/auth" to="/"/>*/}
        {/*}*/}

        <Route path="/error" component={ErrorsPage}/>
        <Route path="/logout" component={Logout}/>

        {
          !isAuthorized ?
            <AuthLayout >
              <Suspense fallback={<LayoutSplashScreen/>}>
                <Switch>
                  {/*FIX ME*/}
                  <Redirect exact from="/" to="/auth"/>
                  <Redirect exact from="/auth" to="/auth/login"/>
                  {/*<div>*/}
                  {/*  <Route path="/users/:id/delete">*/}
                  {/*    {({ history, match }) => (*/}
                  {/*      <DeleteModal*/}
                  {/*        show={match != null}*/}
                  {/*        id={match && match.params.id}*/}
                  {/*        onHide={() => {*/}
                  {/*          history.push("/users/list");*/}
                  {/*        }}*/}
                  {/*      />*/}
                  {/*    )}*/}
                  {/*  </Route>*/}
                  {/*</div>*/}
                  {
                    getRoutes('auth').map(route=>(<ContentRoute key={route.id} {...route} />))
                  }
                </Switch>
              </Suspense>
            </AuthLayout> :
            <Layout>
              <Suspense fallback={<LayoutSplashScreen/>}>
                <Switch>
                  {/*todo redirect /tasks*/}
                  <Redirect exact from="/" to="/users"/>
                  <Redirect exact from="/auth/login" to="/"/>
                  {
                    Object.values(MODULES).map(moduleName=>(
                      <Redirect
                        key={moduleName}
                        exact
                        from={`/${moduleName}`}
                        to={`/${moduleName}/list`}
                      />
                    ))
                  }


                  {
                    getRoutes()
                      // .filter(route=>route.context !== 'auth')
                      .map(route=>(
                        <ContentRoute key={route.id} {...route} />
                        ))
                  }
                  <Redirect to="/error"/>
                </Switch>
              </Suspense>
            </Layout>

        }
        {/*{!isAuthorized ? (*/}
        {/*  <Redirect to="/auth/login"/>*/}
        {/*) : (*/}
        {/*  <Layout>*/}
        {/*    <BasePage/>*/}
        {/*  </Layout>*/}
        {/*)}*/}
      </Switch>
    </>
  );
}
