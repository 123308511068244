import React, {useEffect} from "react";
import { useDispatch } from "react-redux";
import {useIntl} from "react-intl";
import {
  AEAutocompleteField,
  AEDatepickerField,
  AEField,
  AEFileField
} from "../../../../../_metronic/_partials/controls/forms/AEField";
import {fetchCitiesForLocationsEdit, useCitiesLocationsEditState} from "../../../../../redux/store/cities";
import {useModelsForReportsEditState, fetchModelsForReportsEdit} from "../../../../../redux/store/models";
import {getFullName} from "../../../Users/components/FullName";
import {getStatus, getStatusKeys, Status} from "../../../Tasks/components/Status";
import {getPeriodKeys, Period} from "../../components/Period";


//todo server validation errors
export const Form = ({
                       values,
                       errors,
                       touched,
                       handleChange,
                       handleBlur,
                       handleSubmit,
                       isSubmitting,
                       btnRef,
}) => {
  const dispatch = useDispatch();
  const {formatMessage} = useIntl();
  const {data: models} = useModelsForReportsEditState()

  useEffect(()=>{
    dispatch(fetchModelsForReportsEdit())
  }, [dispatch])
  return (
    <div className="form form-label-right">
      <div className="form-group row">
        <div className={"col-lg-12"}>
          <AEField
            name="title"
            label={'TITLE'}
          />
        </div>
      </div>
      <div className="form-group row">
        <div className={`col-md-6`}>
          <AEAutocompleteField
            name="model"
            label={'MODEL'}
            options={models}
            getOptionLabel={o=>o.name}
          />
        </div>
        <div className={`col-md-6`}>
          <AEAutocompleteField
            name="periodic"
            label={'PERIODIC'}
            options={getPeriodKeys()}
            getOptionLabel={o=>formatMessage({id: o})}
            renderOption={o=><Period type={o}/>}
          />
        </div>
      </div>
      <div className="form-group row">
        <div className={"col-lg-12"}>
          <AEFileField
            name="attachments"
            label={'ATTACHMENTS'}
            multiple
          />
        </div>
      </div>
      <div className="form-group row">
        <div className={"col-lg-12"}>
          <AEField
            name="description"
            label={'DESCRIPTION'}
            type={'textarea'}
            rows={12}
          />
        </div>
      </div>

      <button
        style={{ display: "none" }}
        ref={btnRef}
        onClick={handleSubmit}
      />
    </div>
  );
}

